import React from 'react';
import './css/Commercial.css';

import ShantaiAparment from './assets/image/building/shantai_apartment.avif'
import ShantaiHomes from './assets/image/building/shantai_homes.avif'
import ShantaiGreen from './assets/image/building/shantai_green.avif'
import ShantaiGreen2 from './assets/image/building/shantai_green_2.avif'
import ShantaiClassic from './assets/image/building/shantai_classic.avif'
import ShantaiHeritage from './assets/image/building/shantai_heritage.avif'
import ShantaiHeights from './assets/image/building/shantai_heights.avif'
import ShantaiLaxmiCorner from './assets/image/building/laxmicorner.avif'
import ElenaHomes from './assets/image/building/elena_homes.avif'
import RoySir from './assets/image/building/roy_apartment.avif'
import ShantaiResidency from './assets/image/building/shantai_residency.avif'
import DivineHomes from './assets/image/building/divine_homes.avif'
import DivineBless from './assets/image/building/divine_bless.avif'
import DivineVastu from './assets/image/building/divine_vastu.avif'
import ShantaiClassicPhase2 from './assets/image/building/shantai_classic_2.avif'
import ShantaiCorner from './assets/image/building/shantaicorner.avif'
import Drishti from './assets/image/building/drishti_developers.avif'
import IBMDevelopers from './assets/image/building/ibm_developers.avif'
import ResidentialBanner from './assets/image/resi-bg.avif'

function Projects() {
    return (
        <div>
            {/* About Us Banner Start */}
            <div className='about-banner'>
                 <img loading="lazy" alt="Failed to Load" src={ResidentialBanner} width={'100%'} height={'100%'} />
                <div className='banner-path'>
                    <h1>Projects</h1>
                    <p><a href='/home'>Home</a> &#8594; Projects</p>
                </div>
            </div>
            {/* About Us Banner End */}

            {/* Total Residential Projects Start */}
            <div className='project-status'>
                <h1>Completed Project</h1>
            </div>
            <div className='comm-projects'>
                <div className='projects'>
                    <a href='/projects/completed/shantai_homes' className='project-img project-card'>
                         <img loading="lazy" alt="Failed to Load" src={ShantaiHomes} className='image project-image' width={'100%'} height={'100%'} />
                        <div className='project-img-over project-card-info'>
                            {/* <h1 className='text'>Residential Projects</h1> */}
                            <h1>Shantai Homes</h1>
                            <p>Chikhali</p>
                        </div>
                    </a>
                    <a href='/projects/completed/shantai_green' className='project-img project-card'>
                         <img loading="lazy" alt="Failed to Load" src={ShantaiGreen} className='image project-image' width={'100%'} height={'100%'} />
                        <div className='project-img-over project-card-info'>
                            {/* <h1 className='text'>Residential Projects</h1> */}
                            <h1>Shantai Green</h1>
                            <p>Ravet</p>
                        </div>
                    </a>
                    <a href='/projects/completed/shantai_classic' className='project-img project-card'>
                         <img loading="lazy" alt="Failed to Load" src={ShantaiClassic} className='image project-image' width={'100%'} height={'100%'} />
                        <div className='project-img-over project-card-info'>
                            {/* <h1 className='text'>Residential Projects</h1> */}
                            <h1>Shantai Classic</h1>
                            <p>Ravet</p>
                        </div>
                    </a>
                    <a href='/projects/completed/shantai_heritage' className='project-img project-card'>
                         <img loading="lazy" alt="Failed to Load" src={ShantaiHeritage} className='image project-image' width={'100%'} height={'100%'} />
                        <div className='project-img-over project-card-info'>
                            {/* <h1 className='text'>Residential Projects</h1> */}
                            <h1>Shantai Heritage</h1>
                            <p>Akurdi</p>
                        </div>
                    </a>
                    <a href='/projects/completed/shantai_heights' className='project-img project-card'>
                         <img loading="lazy" alt="Failed to Load" src={ShantaiHeights} className='image project-image' width={'100%'} height={'100%'} />
                        <div className='project-img-over project-card-info'>
                            {/* <h1 className='text'>Residential Projects</h1> */}
                            <h1>Shantai Heights</h1>
                            <p>Kharalwadi</p>
                        </div>
                    </a>
                    <a href='/projects/completed/shantai_laxmi_corner' className='project-img project-card'>
                         <img loading="lazy" alt="Failed to Load" src={ShantaiLaxmiCorner} className='image project-image' width={'100%'} height={'100%'} />
                        <div className='project-img-over project-card-info'>
                            {/* <h1 className='text'>Residential Projects</h1> */}
                            <h1>Shantai's Laxmi Corner</h1>
                            <p>Akurdi</p>
                        </div>
                    </a>
                    <a href='/projects/completed/elena_homes' className='project-img project-card'>
                         <img loading="lazy" alt="Failed to Load" src={ElenaHomes} className='image project-image' width={'100%'} height={'100%'} />
                        <div className='project-img-over project-card-info'>
                            {/* <h1 className='text'>Residential Projects</h1> */}
                            <h1>Shantai's Elena Homes</h1>
                            <p>Ravet</p>
                        </div>
                    </a>
                    <a href='/projects/completed/shantai_residency' className='project-img project-card'>
                         <img loading="lazy" alt="Failed to Load" src={ShantaiResidency} className='image project-image' width={'100%'} height={'100%'} />
                        <div className='project-img-over project-card-info'>
                            {/* <h1 className='text'>Residential Projects</h1> */}
                            <h1>Shantai Residency</h1>
                            <p>Rahatani</p>
                        </div>
                    </a>
                    <a href='/projects/completed/shantai_apartment' className='project-img project-card'>
                         <img loading="lazy" alt="Failed to Load" src={ShantaiAparment} className='image project-image' width={'100%'} height={'100%'} />
                        <div className='project-img-over project-card-info'>
                            {/* <h1 className='text'>Residential Projects</h1> */}
                            <h1>Shantai Apartment</h1>
                            <p>Rahatani</p>
                        </div>
                    </a>
                    <a href='/projects/completed/roysir' className='project-img project-card'>
                         <img loading="lazy" alt="Failed to Load" src={RoySir} className='image project-image' width={'100%'} height={'100%'} />
                        <div className='project-img-over project-card-info'>
                            {/* <h1 className='text'>Residential Projects</h1> */}
                            <h1>Roy Apartment</h1>
                            <p>Ravet</p>
                        </div>
                    </a>
                    <a href='/projects/completed/divine_homes' className='project-img project-card'>
                         <img loading="lazy" alt="Failed to Load" src={DivineHomes} className='image project-image' width={'100%'} height={'100%'} />
                        <div className='project-img-over project-card-info'>
                            {/* <h1 className='text'>Residential Projects</h1> */}
                            <h1>Shantai's Divine Homes</h1>
                            <p>Ravet</p>
                        </div>
                    </a>
                    <a href='/projects/completed/divine_bless' className='project-img project-card'>
                         <img loading="lazy" alt="Failed to Load" src={DivineBless} className='image project-image' width={'100%'} height={'100%'} />
                        <div className='project-img-over project-card-info'>
                            {/* <h1 className='text'>Residential Projects</h1> */}
                            <h1>Shantai's Divine Bless</h1>
                            <p>Ravet</p>
                        </div>
                    </a>
                    <a href='/projects/completed/shantai_corner' className='project-img project-card'>
                         <img loading="lazy" alt="Failed to Load" src={ShantaiCorner} className='image project-image' width={'100%'} height={'100%'} />
                        <div className='project-img-over project-card-info'>
                            {/* <h1 className='text'>Residential Projects</h1> */}
                            <h1>Shantai Corner</h1>
                            <p>Ravet</p>
                        </div>
                    </a>
                </div>
            </div>

            {/* Ongoing Projects */}
            <div className='project-status'>
                <h1>Ongoing Project</h1>
            </div>
            <div className='comm-projects'>
                <div className='projects'>
                    <a href='/projects/ongoing/divine_vastu' className='project-img project-card'>
                         <img loading="lazy" alt="Failed to Load" src={DivineVastu} className='image project-image' width={'100%'} height={'100%'} />
                        <div className='project-img-over project-card-info'>
                            {/* <h1 className='text'>Residential Projects</h1> */}
                            <h1>Divine Vastu</h1>
                            <p>Punawale</p>
                        </div>
                    </a>
                    <a href='/projects/ongoing/shantai_green_2' className='project-img project-card'>
                         <img loading="lazy" alt="Failed to Load" src={ShantaiGreen2} className='image project-image' width={'100%'} height={'100%'} />
                        <div className='project-img-over project-card-info'>
                            {/* <h1 className='text'>Residential Projects</h1> */}
                            <h1>Shantai Green<br/>Phase 2</h1>
                            <p>Ravet</p>
                        </div>
                    </a>
                    <a href='/projects/ongoing/drishti' className='project-img project-card'>
                         <img loading="lazy" alt="Failed to Load" src={Drishti} className='image project-image' width={'100%'} height={'100%'} />
                        <div className='project-img-over project-card-info'>
                            {/* <h1 className='text'>Residential Projects</h1> */}
                            <h1>Drishti Developers</h1>
                            <p>Ravet</p>
                        </div>
                    </a>
                    <a href='/projects/ongoing/ibm_developer' className='project-img project-card'>
                         <img loading="lazy" alt="Failed to Load" src={IBMDevelopers} className='image project-image' width={'100%'} height={'100%'} />
                        <div className='project-img-over project-card-info'>
                            {/* <h1 className='text'>Residential Projects</h1> */}
                            <h1>IBM Developer</h1>
                            <p>Chikhali</p>
                        </div>
                    </a>
                </div>
            </div>

            {/* Upcoming Projects */}
            <div className='project-status'>
                <h1>Upcoming Project</h1>
            </div>
            <div className='comm-projects'>
                <div className='projects'>
                    <a href='/projects/upcoming/shantai_classic_phase_2' className='project-img project-card'>
                         <img loading="lazy" alt="Failed to Load" src={ShantaiClassicPhase2} className='image project-image' width={'100%'} height={'100%'} />
                        <div className='project-img-over project-card-info'>
                            {/* <h1 className='text'>Residential Projects</h1> */}
                            <h1>Shantai Classic<br />Phase 2</h1>
                            <p>Ravet</p>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Projects;
