import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import {
  Nav,
  NavLogoContainer,
  Logo,
  CompanyName,
  Bars,
  NavMenu,
  DropdownHover,
  DropdownContent,
} from "./navbarElements";

import LogoImage from '../assets/vta-logo.avif';

const Navbar = () => {
  const [isopen, setisopen] = useState(0);
  const [lastScrollY, setLastScrollY] = useState(0);

  const toggleMenu = () => {
    setisopen(isopen === 0 ? 1 : 0);
  };

  const closeMenu = () => {
    setisopen(0);
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > lastScrollY) {
        setisopen(0);
      }
      setLastScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollY]);

  return (
    <>
      <Nav>
        <NavLogoContainer>
          <Logo src={LogoImage} alt="Logo" width={'60px'} height={'60px'} />
          <CompanyName>VTA Buildcon</CompanyName>
        </NavLogoContainer>
        <Bars onClick={toggleMenu} />
        <NavMenu isopen={isopen}>
          <NavLink to="/home" onClick={closeMenu} className={({ isActive }) => (isActive ? 'active' : '')} style={{ marginRight: '20px' }}>Home</NavLink>
          <NavLink to="/about" onClick={closeMenu} className={({ isActive }) => (isActive ? 'active' : '')} style={{ marginRight: '20px' }}>About</NavLink>
          <DropdownHover>
            <NavLink to="/projects" onClick={closeMenu} className={({ isActive }) => (isActive ? 'active' : '')} style={{ marginRight: '20px' }}>Projects</NavLink>
            <DropdownContent>
              <NavLink to="/projects/ongoing" onClick={closeMenu} className={({ isActive }) => (isActive ? 'active' : '')}>Ongoing</NavLink>
              <NavLink to="/projects/completed" onClick={closeMenu} className={({ isActive }) => (isActive ? 'active' : '')}>Completed</NavLink>
              <NavLink to="/projects/upcoming" onClick={closeMenu} className={({ isActive }) => (isActive ? 'active' : '')}>Upcoming</NavLink>
            </DropdownContent>
          </DropdownHover>
          <NavLink to="/contact" onClick={closeMenu} className={({ isActive }) => (isActive ? 'active' : '')}>Contact</NavLink>
        </NavMenu>
      </Nav>
    </>
  );
};

export default Navbar;
